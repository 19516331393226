import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store/Index.js";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!store.state.token;
  const userRole = store.getters.role; 

  if (to.meta.requiresAuth && !isLoggedIn) {
    // Usuario no autenticado, redirigir a la página de login
    next({ name: 'login' });
  } else if (to.meta.requiresAuth && to.meta.role && !to.meta.role.includes(userRole)) {
    // Redirige o muestra un mensaje de error si el usuario no tiene permiso
    //next({ name: 'unauthorized' });
    next({ name: 'login' });
  } else {
    // Usuario autenticado y tiene permisos adecuados (o no se requiere autenticación), continuar
    next();
  }
});

export default router;
