import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Login from "@/pages/Login.vue";
// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
//company
const CompanyTable = () => import("@/pages/Companies/CompanyTable.vue");
const EditCompany = () => import("@/pages/Companies/EditCompany.vue");
const NewCompany = () => import("@/pages/Companies/NewCompany.vue");
//customer
const CustomerTable = () => import("@/pages/Customer/CustomerTable.vue");
const NewCustomer = () => import("@/pages/Customer/NewCustomer.vue");
const EditCustomer = () => import("@/pages/Customer/EditCustomer.vue");
//slots
const Calendar = () => import("@/pages/Slots/Calendar.vue");
//drivers
const DriverTable = () => import("@/pages/Driver/DriverTable.vue");
const EditDriver = () => import("@/pages/Driver/EditDriver.vue");
const NewDriver = () => import("@/pages/Driver/NewDriver.vue");
const HoursAvailable = () => import("@/pages/Driver/HoursAvailable.vue");
const DriverIndex = () => import("@/pages/Driver/DriverIndex.vue");
//orders
const OrderTable = () => import("@/pages/Orders/OrderTable.vue");
const NewOrder = () => import("@/pages/Orders/NewOrder.vue");
const EditOrder = () => import("@/pages/Orders/EditOrder.vue");
//fees
const NewFee = () => import("@/pages/Fees/NewFee.vue");
const EditFee = () => import("@/pages/Fees/EditFee.vue");
const FeeTable = () => import("@/pages/Fees/FeeTable.vue");
//services
const NewService = () => import("@/pages/Service/NewService.vue");
const EditService = () => import("@/pages/Service/EditService.vue");
const ServiceTable = () => import("@/pages/Service/ServiceTable.vue");
//Check Api Old
const CheckApi = () => import("@/pages/Check/CheckApi.vue");
const CheckApiTotalizador = () => import("@/pages/Check/CheckApiTotalizador.vue");
const SlotsIndex = () => import("@/pages/Slots/SlotsIndex.vue");

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },

  },
  {
    path: "/",
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "slots/index",
        name: "slots_index",
        component: SlotsIndex,
        meta: { requiresAuth: true },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "apidelivery/showinfo",
        name: "check_api",
        component: CheckApi,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "apidelivery/calculate_statics",
        name: "check_api_totalizador",
        component: CheckApiTotalizador,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "services/create",
        name: "new_service",
        component: NewService,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "services/edit/:id",
        name: "edit_service",
        component: EditService,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "services",
        name: "service",
        component: ServiceTable,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "fees/create",
        name: "new_fee",
        component: NewFee,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "fees/edit/:id",
        name: "edit_fee",
        component: EditFee,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "fees",
        name: "fees",
        component: FeeTable,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "delivery/create",
        name: "new_order",
        component: NewOrder,
        meta: { requiresAuth: true, role: ['superAdmin', 'company']  },
      },
      {
        path: "delivery/edit/:id",
        name: "edit_order",
        component: EditOrder,
        meta: { requiresAuth: true, role: ['superAdmin', 'company'] },
      },
      {
        path: "hours",
        name: "hours_available",
        component: HoursAvailable,
        meta: { requiresAuth: true, role: ['superAdmin', 'driver'] },

      },
      {
        path: "slots",
        name: "calendar",
        component: Calendar,
        meta: { requiresAuth: true, role: 'superAdmin' },
      },
      {
        path: "customers",
        name: "customers",
        component: CustomerTable,
        meta: { requiresAuth: true, role: ['superAdmin', 'company'] },

      },
      {
        path: "customers/edit/:id",
        name: "edit_customer",
        component: EditCustomer,
        meta: { requiresAuth: true, role: ['superAdmin', 'company'] },

      },
      {
        path: "customers/create",
        name: "create_customer",
        component: NewCustomer,
        meta: { requiresAuth: true, role: ['superAdmin', 'company'] },

      },
      {
        path: "company",
        name: "companies",
        component: CompanyTable,
        meta: { requiresAuth: true, role: 'superAdmin' },

      },
      {
        path: "company/edit/:id",
        name: "edit_company",
        component: EditCompany,
        meta: { requiresAuth: true, role: 'superAdmin' },

      },
      {
        path: "company/create",
        name: "create_company",
        component: NewCompany,
        meta: { requiresAuth: true, role: 'superAdmin' },

      },
      {
        path: "drivers",
        name: "driver",
        component: DriverTable,
        meta: { requiresAuth: true, role: 'superAdmin' },

      },
      {
        path: "driver_index",
        name: "driver_index",
        component: DriverIndex,
        meta: { requiresAuth: true, role: ['superAdmin', 'driver'] },
      },
      {
        path: "drivers/edit/:id",
        name: "edit_driver",
        component: EditDriver,
        meta: { requiresAuth: true, role: 'superAdmin' },

      },
      {
        path: "drivers/create",
        name: "create_driver",
        component: NewDriver,
        meta: { requiresAuth: true, role: 'superAdmin' },

      },
      {
        path: "delivery",
        name: "order",
        component: OrderTable,
        meta: { requiresAuth: true, role: ['superAdmin', 'company']},

      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
        meta: { requiresAuth: true },
      },

      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
