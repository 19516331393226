import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const savedState = JSON.parse(localStorage.getItem('vuex-state'));

const store = new Vuex.Store({
    state: savedState ? savedState : {
        user: {
            id: 0,
            email: 'dsa@asd.com',
            name: 'johndoe',
            phone: '+698698698698'
        },
        token: '',
        role: '',
        status: false,
        latitude: null,
        longitude: null
    },
    getters: {
        role: state=> state.role
    },
    mutations: {
        storeUser(state, data) {
            state.user = data;
        },
        storeToken(state, data) {
            state.token = data;
        },
        logout(state) {
            state.token = null;
            state.user = {
                id: 0,
                email: '',
                name: '',
                phone: ''
            };
            state.role = '';
            state.status = false;
            state.latitude = null;
            state.longitude = null;
        },
        setRole(state, data) {
            state.role =data;
        },
        setOnline(state, data) {
            state.status = data
        },
        lat(state, data) {
            state.latitude = data
        },
        lon(state, data) {
            state.longitude = data
        }

    },
    actions: {

    },
    modules: {

    }
});

// Guardamos el estado en el LocalStorage cada vez que cambie
store.subscribe((mutation, state) => {
    localStorage.setItem('vuex-state', JSON.stringify(state));
});

export default store;
